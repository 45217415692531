<template>
  <n-layout-footer style="text-align: center; overflow: auto;">
    联系我们：vx:chen2023go<br>© 版权所有 - Benecd.Ai | 2022 -2023 
  </n-layout-footer>
</template>

<script>
import { NLayoutFooter } from "naive-ui";

export default {
  components: {
    NLayoutFooter,
  },
};
</script>
