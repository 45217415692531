<template>
    <n-layout style="height: 100vh; overflow: auto ">
        <PageHeader />
    <!-- 头部布局 -->
        <n-layout-content style="padding-top: 64px; height: 90vh; "> <!-- 假设标头高度为 64px -->
            <div>
            <div class="loading" v-show="loading">gif动图生成中，请稍等......</div>
            <div class="title" style="display: flex; justify-content: center; align-items: center;">
                当前使用模式：<span class="dymch" >{{ textDrawMode }}</span>
                <n-button class="msqh"  @click="changeMode">【模式切换】</n-button>
            </div>
            <n-form >
              <n-form-item label="上传文件" style="display: flex; justify-content: center; align-items: center;">
                <label for="upload-btn">
                  <input type="file" id="upload-btn" style="display: flex; margin-left: 90px;" @change="handleImageUpload" />
                </label>
              </n-form-item>
              
              <n-form-item label="画笔颜色" style="display: flex; justify-content: center; align-items: center;">
                <span style="margin-right: 8px;">画笔颜色：</span>
                <n-color-picker v-model:value="colorSelector" style="width: 100px;" />
              </n-form-item>
              
              <n-form-item label="画笔大小" style="display: flex; justify-content: center; align-items: center; margin-bottom: 10px;">
                <span style="margin-right: 8px;">画笔大小：</span>
                <n-slider v-model:value="brushSizeSelector" :min="1" :max="50"  style="width: 100px;"/>
              </n-form-item>
            </n-form>


            <div id="drawList">
                    <div v-for="(frame, index) in gifFrames" :key="index">
                    <canvas ref="frameCanvas" :width=100 :height=100 ></canvas>
                    </div>
            </div>
            <canvas ref="canvasRef" width="500" height="500" 
                    @mousedown="handleMouseDown" 
                    @mousemove="handleMouseMove"
                    @mouseup="handleMouseUp"
                    @touchstart="handleTouchStart" 
                    @touchmove="handleTouchMove"
                    @touchend="handleTouchEnd">
            </canvas>
            <div class="buttonBox">
                <n-button @click="drawNextFrame" v-show="drawMode === 'multi'">添加到动图中</n-button>
                <n-button @click="generateGif">生成GIF</n-button>
                <n-button @click="refresh">刷新</n-button>
            </div>
            <div class="gifResLitTit" style="display: flex; justify-content: center; align-items: center; margin-top: 10px;">生成的GIF动图列表:</div>
            <div id="gifResList">
                <a v-for="(gifRes, index) in gifResList" :key="index" :href="gifRes.url" :download="gifRes.filename">
                <img :src="gifRes.url" :alt="gifRes.filename" />
                <span>{{ gifRes.filename }}</span>
                </a>
            </div>
            </div>
        </n-layout-content>
        <PageFooter />
    </n-layout>

  </template>
  
  <script>
  import { ref, onMounted, require } from 'vue';
  import GIF from 'gif.js';
  import { throttle } from 'lodash';
  import { NButton, NColorPicker, NSlider, NLayout,  NLayoutContent } from 'naive-ui';
  import PageFooter from "@/components/PageFooter.vue";
  import PageHeader from "@/components/PageHeader.vue";
  
  export default {
    components: {
      NButton,
      NColorPicker,
      NSlider,
      NLayout,
      // NLayoutHeader,
      NLayoutContent,
      // NAvatar,
      // NDropdown,
      PageFooter,
      PageHeader
    },
    setup() {
     
      const canvasRef = ref(null);
      const gifFrames = ref([]);
      const gifResList = ref([]);
      const loading = ref(false);
      const isDrawing = ref(false);
      const drawMode = ref('');
      const gif = ref(null);
      const colorSelector = ref('#000000');
    //   const nextFrameBtn = ref(null);
      const brushSizeSelector = ref(10);
      const textDrawMode = ref('');
      const frameCanvas = ref(null);

      const init = () => {
        gif.value = new GIF({
          workers: 2,
          quality: 5,
          debug: true,
          width: canvasRef.value.width,
          height: canvasRef.value.height,
          WorkerPath: '/gif.worker.js'
        });
        console.log(gif.value);
        const ctx = canvasRef.value.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.fillRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        drawMode.value = selectDrawMode();
        textDrawMode.value = drawMode.value === 'multi' ? '多页面成画' : '一次成画';

        console.log('你选了', drawMode.value);
        // nextFrameBtn.value.style.display = drawMode.value === 'multi' ? 'inline-block' : 'none';
      };

      const init_refresh = () => {
        gif.value = new GIF({
          workers: 2,
          quality: 5,
          debug: true,
          width: canvasRef.value.width,
          height: canvasRef.value.height,
          WorkerPath: '/gif.worker.js'
        });
        console.log(gif.value);
        const ctx = canvasRef.value.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.fillRect(0, 0, canvasRef.value.width, canvasRef.value.height);
        textDrawMode.value = drawMode.value === 'multi' ? '多页面成画' : '一次成画';

        // nextFrameBtn.value.style.display = drawMode.value === 'multi' ? 'inline-block' : 'none';
      };
  
      const selectDrawMode = () => {
        let mode;
        let isValidMode = false;
        while (!isValidMode) {
            mode = prompt(`请选择是"单页面成画"还是"多页面成画"？\r
            单页面成画：指单个画布上连续绘制，每一笔绘制的过程都会录制动图。\r
            多页面成画是：指每次绘制完一个页面需要切换下一个页面绘制下一个动画，不会记录笔画。\r
            输入"single"代表一次成画，输入"multi"代表多页面成画`, 'multi');
            mode = mode?.trim();
            if (mode === 'single' || mode === 'multi') {
            isValidMode = true;
            } else {
            alert('请选择正确的绘制模式');
            }
        }
        return mode;
        };
  
      const changeMode = () => {
        if (gif.value.frames.length) {
          let reallyChange = confirm('注意：当前绘画过程中，不建议切换模式！！！如果强行切换，将会导致绘画数据丢失，请再三考虑');
          if (reallyChange) {
            drawMode.value = '';
            reset();
          }
        } else {
          drawMode.value = '';
          reset();
        }
      };
  
      const handleImageUpload = (event) => {
        const file = event.target.files[0];
        
        if (file && file instanceof Blob) {
            const reader = new FileReader();

            reader.onload = function (event) {
            const img = new Image();
            img.src = event.target.result;
            img.onload = function () {
                const ctx = canvasRef.value.getContext('2d');
                ctx.drawImage(img, 0, 0, canvasRef.value.width, canvasRef.value.height);
            };
            };

            reader.readAsDataURL(file);
        }
        };
  
    //   const addFunc = throttle(() => {
    //     console.log('添加画布帧');
    //     const ctx = canvasRef.value.getContext('2d');
    //     gif.value.addFrame(ctx, { copy: true, delay: 200 });
    //     }, 200);
  
      const drawNextFrame = () => {
        if (drawMode.value === 'multi') {
          console.log('添加画布帧');
          const ctx = canvasRef.value.getContext('2d');
          gif.value.addFrame(ctx, { copy: true, delay: 200 });
          ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
          ctx.fillStyle = 'rgb(255, 255, 255)';
          ctx.fillRect(0, 0, canvasRef.value.width, canvasRef.value.height);
          updateDrawList();
        }
      };
  
      const updateDrawList = () => {
        gifFrames.value = [];
        console.log("many frame:", gif.value.frames.length)
        gif.value.frames.forEach((frame, index) => {
            const w = gif.value.options.width;
            const h = gif.value.options.height;
            const imageData = new ImageData(new Uint8ClampedArray(frame.data), w, h);
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = w;
            tempCanvas.height = h;
            tempCanvas.style.cssText = 'width:100px;height:100px';
            const ctx = tempCanvas.getContext('2d');
            ctx.putImageData(imageData, 0, 0);

            // 关键点：确保 Vue 更新 DOM 后再进行操作
            window.requestAnimationFrame(() => {
            const canvas = frameCanvas.value[index]; // 注意 ref 的使用（下面有详细说明）
            if (canvas) { // 确保 canvas 存在
                const canvasCtx = canvas.getContext('2d');
                // ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
                canvasCtx.drawImage(tempCanvas, 0, 0, w, h, 0, 0, 100, 100); // 缩放到100x100大小
            }
            });

            gifFrames.value.push(tempCanvas);
        });
        };

  
      const reset = () => {
        gif.value = null;
        init();
        updateDrawList();
      };

      const reset_refresh = () => {
        gif.value = null;
        init_refresh();
        updateDrawList();
      };
  
      const generateGif = () => {
        try {
          loading.value = true;
          gif.value.on('finished', function (blob) {
            loading.value = false;
            const img = document.createElement('img');
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            img.src = url;
            link.href = url;
            link.textContent = '点击下载动图';
            link.download = 'myGif.gif';
            link.appendChild(img);
            gifResList.value.push({ url, filename: 'myGif.gif' });
            reset_refresh();
          });
  
          gif.value.render();
        } catch (error) {
          loading.value = false;
          alert('已经生成了动图了，何须一直点点点呢');
        }
      };

      const handleMouseDown = (event) => {
        const ctx = canvasRef.value.getContext('2d');
        ctx.beginPath();
        ctx.moveTo(event.offsetX, event.offsetY);
        isDrawing.value = true;
    };

    const handleMouseMove = throttle((event) => {
      if (isDrawing.value && canvasRef.value) {
        const ctx = canvasRef.value.getContext('2d');
        ctx.lineWidth = brushSizeSelector.value;
        ctx.strokeStyle = colorSelector.value;

        ctx.lineTo(event.offsetX, event.offsetY);
        ctx.stroke();

        if (drawMode.value === 'single') {
          addFunc();
        }
      }
    }, 10);

    const handleMouseUp = () => {
      if (isDrawing.value && canvasRef.value) {
        isDrawing.value = false;
        // const ctx = canvasRef.value.getContext('2d');
        // if (drawMode.value === 'multi') {
        //   const imageData = ctx.getImageData(0, 0, canvasRef.value.width, canvasRef.value.height);
        //   gif.value.addFrame(imageData, { delay: 200 });
        // }
      }
    };

    const handleTouchStart = (event) => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.beginPath();
      const offsetX = event.touches[0].clientX - canvasRef.value.getBoundingClientRect().left;
      const offsetY = event.touches[0].clientY - canvasRef.value.getBoundingClientRect().top;
      ctx.moveTo(offsetX, offsetY);
      isDrawing.value = true;
    };

    const handleTouchMove = throttle((event) => {
      if (isDrawing.value && canvasRef.value) {
        const ctx = canvasRef.value.getContext('2d');
        ctx.lineWidth = brushSizeSelector.value;
        ctx.strokeStyle = colorSelector.value;

        const offsetX = event.touches[0].clientX - canvasRef.value.getBoundingClientRect().left;
        const offsetY = event.touches[0].clientY - canvasRef.value.getBoundingClientRect().top;
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();

        if (drawMode.value === 'single') {
          addFunc();
        }
      }
    }, 10);

    const handleTouchEnd = () => {
      if (isDrawing.value && canvasRef.value) {
        isDrawing.value = false;
      }
    };

    const addFunc = throttle(() => {
      const ctx = canvasRef.value.getContext('2d');
      gif.value.addFrame(ctx, { copy: true, delay: 200 });
    }, 100);
  
    onMounted(() => {
        if (!window.GIF) {
            require('../../node_modules/gif.js')
        }
        init();
        const ctx = canvasRef.value.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvasRef.value.width, canvasRef.value.height);
  
    });

    const refresh = () => {
      const ctx = canvasRef.value.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
      gifFrames.value = [];
      loading.value = false;
      isDrawing.value = false;
      colorSelector.value = '#000000';
      brushSizeSelector.value = 10;
      reset_refresh();
    };

      
  
      return {

        canvasRef,
        gifFrames,
        gifResList,
        loading,
        isDrawing,
        drawMode,
        handleImageUpload,
        drawNextFrame,
        changeMode,
        generateGif,
        brushSizeSelector,
        colorSelector,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        textDrawMode,
        frameCanvas,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
        refresh
      };
    },
  };
  </script>
  
  
  <style scoped>
  body {
    text-align: center;
    background-color: #00ff00;
  }

  .title {
    font-size: 30px;
    /* background-color: #222; */
    color: #070303;
    /* border: solid 1px #ccc; */
    /* box-shadow: 0 0 10px 0 #ccc; */
  }

  .confBox {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  canvas {
    border: solid 1px #ccc;
    box-shadow: 0 0 10px 0 #ccc;
  }
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
  }
  .buttonBox {
    margin-top: 10px;
  }
  button {
    width: 100px;
    height: 40px;
    border: none;
    border-radius: 10px;
    background-color: green;
    color: #fff;
    cursor: pointer;
  }
  button:disabled {
    background-color: #ccc;
  }
  #end-btn {
    background-color: red;
  }
  .imgLink {
  }
  #drawList {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 500px;
    overflow-y: auto;
    border: solid 1px #ccc;
    box-shadow: 0 0 10px 0 #ccc;
  }
  #drawList::before {
    content: "动图列表";
  }
  .dymch{
    font-size: 20px; 
    margin-right: 10px;
  }
  @media only screen and (max-width: 768px) {
    #drawList {
      position: relative;
      display: flex; /* 使用 flex 容器 */
      flex-direction: row; /* 子元素沿主轴水平排列 */
      width: 100%; /* 设定一个固定的宽度，也可以是100%，取决于父元素的大小 */
      height: 100px; /* 之前的宽度成为高度 */
      overflow-x: auto; /* 当内容宽度大于容器时可横向滚动 */
      border: solid 1px #ccc;
      box-shadow: 0 0 10px 0 #ccc;
    }
    #drawList::before {
      content: "动图列表";
      writing-mode: vertical-rl; /* 文字垂直方向，从上到下排列 */
    }
    canvas {
      width: 100%;
      
    }
    .title {
      font-size: 20px; }

    .dymch{
      font-size: 10px; 
    }

  }

  .gifResLitTit {
    text-align: left;
  }
  #gifResList {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
  }
  #gifResList a {
    width: 100px;
    height: 120px;
    display: flex;
    border: solid 1px #ccc;
    margin: 5px;
    font-size: 12px;
    flex-direction: column-reverse;
    box-shadow: 0 0 10px 0 #ccc;
    color: #222;
    text-decoration: none;
    padding: 5px;
  }
  
  #gifResList a:hover {
    transform: scale(1.1);
    transition: all ease-in-out 0.5s;
    color: deepskyblue;
  }
  #gifResList a img {
    margin-bottom: 5px;
  }

  @media (max-width: 767px) {
  /* 移动设备（屏幕宽度小于768px时的样式） */
  #gifResList a {
    flex: 0 0 23%; /* 确保每项占据不超过23%的宽度。这能保证每行最多有四项，因为4*23%稍低于100% */
    margin: 3px 1%; /* 调整边距以符合响应布局 */
  }
}
  </style>
  