<template>
  <n-message-provider>
  <n-dialog-provider>
    <RouterView />
  </n-dialog-provider>
</n-message-provider>
</template>
<script>
import { NDialogProvider,NMessageProvider } from 'naive-ui';

export default {
  components: {
    NDialogProvider,
    NMessageProvider
  },
  // ...其他选项
};
// import HelloWorld from './components/HelloWorld.vue'
// import { NConfigProvider } from 'naive-ui';
// import TestLogin from './components/TestLogin.vue';



// export default {
//   name: 'App',
//   components: {
//     HelloWorld
//   }
// }
// export default {
//   name:'app',
//   components: {
//     'my-login': TestLogin
//     // NConfigProvider
//   }
// }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
