import { createRouter, createWebHistory } from 'vue-router';

import WeChatLogin from "../components/WechatLogin.vue"
// import TestLogin from "../components/TestLogin.vue"
import AuthenticationPage from "../components/AuthenticationPage.vue"
import UrlSubmit from "../components/UrlSubmit.vue"
import ToolSelector  from "../components/ToolSelector.vue"
import GifGenerate  from "../components/GifGenerate.vue"
import GifDrawHappy  from "../components/GifDrawHappy.vue"


// 定义路由数组，而不是调用 createRouter
const routes = [ 
    {
        path:'/wechatlogin',
        name: 'WeChatLogin',
        component: WeChatLogin,
        meta: {title: '微信登录'}
    },
    // {
    //     path:'/',
    //     name: 'TestLogin',
    //     component: TestLogin,
    //     meta: {title: '登录'}
    // },
    {   
        path:'/',
        name: 'AuthenticationPage',
        component: AuthenticationPage,
        meta: {title: '登录'}
    },
    {
        path:'/submiturl',
        name: 'UrlSubmit',
        component: UrlSubmit,
        meta: {
            title: '知乎内容提取',
            requiresAuth: true // 在此添加requiresAuth属性
        }
    },
    {
        path:'/toolselector',
        name: 'ToolSelector',
        component: ToolSelector ,
        meta: {
            title: '工具',
            requiresAuth: true // 在此添加requiresAuth属性
        }
    },
    {
        path:'/gifgenerate',
        name: 'GifGenerate',
        component: GifGenerate,
        meta: {
            title: 'Gif生成工具',
            requiresAuth: true // 在此添加requiresAuth属性
        },
    
    },
    {
        path:'/GifDrawHappy',
        name: 'GifDrawHappy',
        component: GifDrawHappy,
        meta: {
            title: 'Gif作画工具',
            requiresAuth: true // 在此添加requiresAuth属性
        },
    
    }

];

// 创建一个 new router instance，只调用一次 createRouter
const router = createRouter({
    history: createWebHistory(), // 需要加上这个来使用 HTML5 history mode
    routes, // 传递路由配置
    
});

router.beforeEach((to, from, next) => {
    // console.log(to.matched);
    // 更新页面标题
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }

    // 检查路由是否需要认证
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const authToken = localStorage.getItem('authToken');

        // 如果没有token且需要认证，重定向到登录页面
        if (!authToken) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            });
        } else {
            // 如果有token，继续访问目标路由
            next();
        }
    } else {
        // 如果不需要认证，直接放行
        next();
    }
});

export default router;
