<template>
  
    <div class="centered-container" >
        <div class="tabs-container">
            <n-tabs v-model:value="activeTab">
                <n-tab-pane name="login" tab="登录">
                    <n-form>
                        <!-- 可以切换用户名登录与邮箱登录
                        <n-form-item label="手机号">
                            <n-input v-model:value="loginCredentials.username" placeholder="请输入手机号"></n-input>
                        </n-form-item>
                        <n-form-item label="密码">
                            <n-input v-model:value="loginCredentials.password" placeholder="请输入密码"
                                type="password"></n-input>
                        </n-form-item>
                        <n-form-item>
                            <n-button type="primary" @click="handleUserLogin" block>登录</n-button>
                        </n-form-item> -->
                        <n-form-item>
                            <div>第三方登录：</div>
                            <n-button class="button-custom-width_login" @click="handleWechatLogin">
                                <template #icon>
                                <img src="../assets/wechat2.jpg" alt="微信" class="button-icon" />
                                </template>
                                微信登录
                            </n-button>
                            <!-- <n-button class="button-custom-width" @click="handleQQLogin">
                                <template #icon>
                                <img src="../assets/qq.jpg" alt="QQ" class="button-icon" />
                                </template>
                                QQ登录
                            </n-button> -->
                        </n-form-item>
                    </n-form>
                </n-tab-pane>
                <!-- <n-tab-pane name="register" tab="注册">
                    
                    <n-form @submit.prevent="handleRegister">
                       
                        <n-form-item label="手机号" >
                            <n-input 
                                v-model:value="registerCredentials.phone" 
                                placeholder="请输入手机号" 
                                @input="checkPhoneValidity"
                                :status="!isPhoneValid && registerCredentials.phone.length > 0 ? 'error' : ''">
                            </n-input>
                            <template #feedback>
                                <div v-if="!isPhoneValid && registerCredentials.phone.length > 0">手机号错误</div>
                            </template>
                            </n-form-item>

                            <div class="code-verification-container" v-if="isPhoneValid">
                            <n-form-item>
                                <n-button @click="handleSendCode" :loading="isSendingCode" :disabled="countdown.value > 0">
                                    {{ sendButtonText }}
                                </n-button>

                            </n-form-item>
                            <n-form-item v-if="showCodeInput">
                                <n-input v-model:value="registerCredentials.code" placeholder="请输入验证码"></n-input>
                            </n-form-item>
                            </div>
                   

                    <n-form-item label="用户名">
                        <n-input v-model:value="registerCredentials.username" placeholder="请输入用户名"></n-input>
                    </n-form-item>
                    <n-form-item label="密码">
                        <n-input v-model:value="registerCredentials.password" placeholder="请输入密码"
                                type="password"></n-input>
                    </n-form-item>

                    <n-form-item label="确认密码">
                        <n-input v-model:value="registerCredentials.confirmPassword" placeholder="请再次输入密码"
                                type="password"></n-input>
                    </n-form-item>

                    <n-form-item>
                        <n-button type="primary" @click="handleRegister" block>注册</n-button>
                    </n-form-item>
                    </n-form>
                </n-tab-pane> -->
                

                <!-- <n-tab-pane name="phone-login" tab="手机验证码登录">
                    <n-form class="auth-form">
                        <n-form-item label="手机号码">
                            <n-input v-model:value="phoneLoginCredentials.phone" placeholder="请输入手机号码"></n-input>
                        </n-form-item>
                        <n-form-item label="验证码">
                            <n-input v-model:value="phoneLoginCredentials.code" placeholder="请输入验证码" type="text"></n-input>
                            <n-button @click="sendCode" :loading="isSendingCode.value">{{ sendButtonText }}</n-button>
                        </n-form-item>
                        <n-form-item>
                            <n-button type="primary" @click="handlePhoneCodeLogin" block>登录</n-button>
                        </n-form-item>
                        <n-form-item>
                            <div>第三方登录：</div>
                            <n-button class="button-custom-width" @click="handleWechatLogin">
                                <template #icon>
                                <img src="../assets/wechat2.jpg" alt="微信" class="button-icon" />
                                </template>
                                微信登录
                            </n-button>
                            <n-button class="button-custom-width" @click="handleQQLogin">
                                <template #icon>
                                <img src="../assets/qq.jpg" alt="QQ" class="button-icon" />
                                </template>
                                QQ登录
                            </n-button>
                        </n-form-item>
                    </n-form>
                </n-tab-pane> -->
            </n-tabs>
        </div>
    </div>

</template>

<script >
import { ref, computed ,onMounted } from 'vue';
import { NForm, NFormItem, NButton, NTabs, NTabPane,createDiscreteApi,useMessage} from 'naive-ui';
import { useRouter } from 'vue-router';
// 假设已经安装和导入了axios
function isWeixinBrowser() {
  const ua = navigator.userAgent.toLowerCase();
  return (/micromessenger/.test(ua)) ? true : false;
}
function getUrlParameter(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name) || '';
}
import axios from 'axios';
// eslint-disable-next-line

// import {checkLoginStatus, saveLoginStatus} from '../utils/utils.js'

const { dialog } = createDiscreteApi(['dialog']);

export default {
    components: {
        NForm,
        NFormItem,
        // NInput,
        NButton,
        NTabs,
        NTabPane
        
    },
    setup() {
        const router = useRouter(); 
        const authToken = ref('');
        const wechatAuth = async () => {
            if (isWeixinBrowser()) {
                const currentUrl = window.location.href;
                const redirectUrl = encodeURIComponent(currentUrl);
                const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx931b4cc1f7635bbc&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;

                if (!getUrlParameter('code')) {
                window.location.href = authUrl;
                } else {
                try {
                    const response = await axios.get(`https://zhihuurl.wisdomier.com/wechat_login/?code=${getUrlParameter("code")}`);
                    if (response.data.token) {
                    authToken.value = response.data.token;
                    localStorage.setItem('authToken', response.data.token);
                    router.push({ name: "ToolSelector" });
                    }
                } catch (error) {
                    console.error('登录失败:', error);
                }
                }
            } else {
                console.log('非微信浏览器');
                // 在这里处理非微信浏览器的逻辑
            }
            };
        axios.defaults.baseURL  =  'https://zhihuurl.wisdomier.com/';
        // axios.defaults.baseURL  =  'http://127.0.0.1:9000/';
        axios.defaults.headers.post['Content-Type']  =  'application/json';
        axios.defaults.headers.common['X-Requested-With']  =  'XMLHttpRequest';
        axios.defaults.headers.common['Referrer-Policy']  =  'origin';
        axios.defaults.withCredentials  =  true;  //  允许携带  Cookie
         //  检查登录状态并自动跳转到主页
        // const router = useRouter(); 
        // checkLoginStatus(router)

 
        const message=useMessage();
        const activeTab = ref('login');
        const loginCredentials = ref({
            username: '',
            password: ''
        });
        
        const registerCredentials = ref({
            phone:'',
            code:'',
            username: '',
            password: '',
            confirmPassword: '' // 新增字段
            // ...其它注册信息...
        });
        const isPhoneValid = ref(false);
        const showCodeInput = ref(false);
        // Phone login data
        // const sendingCode = ref(false);
        const countdown = ref(0);
        const isSendingCode=ref(false);
        const phoneLoginCredentials = ref({
            phone: '',
            code: ''
        });
        


        const checkPhoneValidity = () => {
    // 使用正则表达式验证手机号
    // 验证号码必须以1开头且总长度为11位
            const phoneRegex = /^1\d{10}$/;
            isPhoneValid.value = phoneRegex.test(registerCredentials.value.phone);
            return isPhoneValid.value;
        };
        const handleSendCode = async () => {
                //         // 检查手机号是否已注册
                // if (!checkPhoneValidity()) {
                //     // 不合法时提醒用户
                //     dialog.error({
                //         title: '注册错误',
                //         content: '请输入合法的手机号！',
                //         positiveText: '确定',
                //         onPositiveClick: () => {},
                //     });
                //     return;
                // }
                try {
                    const phoneCheckResponse = await axios.post('/check_regist_phone/', {
                        phone: registerCredentials.value.phone
                    });
                    console.log(phoneCheckResponse.data.is_regist)
                    if (!phoneCheckResponse.data.is_regist) {
                        dialog.error({
                            title: '注册错误',
                            content: '手机号已被注册，请使用其他手机号！',
                            positiveText: '确定',
                            onPositiveClick: () => {},
                        });
                        return;
                    }
                    showCodeInput.value = true;
                    message.success("验证码发送成功");
                    sendCode()
                    

                } catch (error) {
                    console.error('检查手机号时发生错误:', error);
                    return;
                }
        };
        // const sendButtonText = computed(() => {
        //     if (countdown.value > 0) {
        //         return `重发验证码 (${countdown.value})`;
        //     }
        //     return '发送验证码';
        // });

        // 发送验证码逻辑
        const sendCode = async () => {
            // if (sendingCode.value || countdown.value > 0) return;
            // // 请求发送验证码...
            // sendingCode.value = true;
            if (isSendingCode.value) return; // 如果正在发送，直接返回

            isSendingCode.value = true;

            try {
                // 假设后端提供了发送验证码的API
                const response = await axios.post('/send_code/', { phone: phoneLoginCredentials.value.phone });
                console.log(response.data.is_code); 
                // 机制：60秒内不可重复发送
                if (response.data.is_code) {
                    countdown.value = 60; // 设置倒计时秒数
                    let interval = setInterval(() => {
                        countdown.value--;
                        if (countdown.value <= 0) {
                            clearInterval(interval);
                            isSendingCode.value = false; // 重置发送状态
                        }
                    }, 1000);
                }
                else{
                    console.log('发送验证码失败');
                    isSendingCode.value = false; // 重置发送状态
                }
            } catch (error) {
                // 处理发送验证码错误
                isSendingCode.value = false; // 重置发送状态
            }
        };
        const sendButtonText = computed(() => {
            if (countdown.value > 0) {
                return `重发验证码 (${countdown.value}s)`;
            }
            return '发送验证码';
        });

        const handlePhoneCodeLogin = async () => {
            try {
                
                const response = await axios.post('/verify_code_login/', phoneLoginCredentials.value);
                console.log(response.data.is_login); 
                if(response.data.is_login)
                {
                    // const token = response.data.token;
                    // saveLoginStatus(token, 15); // 保存登录状态 15 天
                    router.push({"name":"UrlSubmit"});
                    console.log(response.data.data);
                }
                // 登录成功，处理响应数据，比如保存token
            } catch (error) {
                // 登录失败，处理错误
                dialog.error({
                            title: '登录错误',
                            content: '验证码错误！',
                            positiveText: '确定',
                            onPositiveClick: () => {},
                        });
            }finally{
                console.error('登录失败');
            }
        };


        const handleRegister = async () => {
            if (registerCredentials.value.password !== registerCredentials.value.confirmPassword) {
                dialog.error({
                    title: '密码验证',
                    content: '两次输入的密码不一致，请重新输入！',
                    positiveText: '确定',
                    onPositiveClick: () => {},
                });
                return;
            }
            // 执行注册流程
            try {
                const createUserPayload = {
                    username: registerCredentials.value.username,
                    password: registerCredentials.value.password,
                    phone: registerCredentials.value.phone,
                    code:registerCredentials.value.code
                };
                const response = await axios.post('/create_user/', createUserPayload);
                if(response.status === 200){
                    dialog.success({
                        title: '注册成功',
                        content: '恭喜你注册成功',
                        positiveText: '确定',
                        onPositiveClick :()=>{
                            message.success("注册成功请登录");
                            registerCredentials.value.username='';
                            registerCredentials.value.password='';
                            registerCredentials.value.phone='';
                            registerCredentials.value.code='';
                            registerCredentials.value.confirmPassword='';
                            isPhoneValid.value = false;
                            isSendingCode.value = false;
                            showCodeInput.value = false;
                            activeTab.value="login"
                        }
                    });
                    console.log(response.data.data);
                    // 注册成功，处理响应数据，可能需要重定向用户或者清理表单
                }
            } catch (error) {
                dialog.error({
                    title:"注册失败",
                    content:error.response.data.detail,
                    positiveText: '确定',
                    onPositiveClick :()=>{}
                })
                console.error('注册失败:', error);
            }
        };


        const handleUserLogin = async () => {
            // 进行手机登录...
            // 你可能需要收集手机号码和验证码，然后发送到你的服务器进行验证
            // 以下是一个伪代码示例，你需要根据实际情况进行操作
            try {
                const response = await axios.post('/user_login/', loginCredentials.value);
                console.log(response.data.is_login);
                if (response.data.is_login) {
                    // const token = response.data.token;
                    // saveLoginStatus(token, 15); // 保存登录状态 15 天
                    router.push({"name":"ToolSelector"});
                    console.log(response.data.data);
                    console.log('登录成功');
                } else {
                    // 登录失败，展示错误信息
                    console.log('登录失败', response.error);
                }
            } catch (error) {
                // 处理错误情况
                console.error('登录过程中出现错误', error);
            }
        };
        

        const handleWechatLogin = () => {
            router.push({"name":"WeChatLogin"})
        }
        // const handleWechatLogin = () => {
        //     // 真实应用中需要调用微信登录SDK
        //     // 以下是一个伪代码示例，你需要调用实际的SDK执行登录流程
        //     try {
        //         wechatSDK.login({
        //         success: function (authInfo) {
        //             // 得到授权信息，进行后续登录处理
        //             console.log('微信登录成功', authInfo);
        //         },
        //         fail: function (error) {
        //             // 处理登录失败的情况
        //             console.error('微信登录失败', error);
        //         }
        //         });
        //     } catch (error) {
        //         // 再加一层错误处理，以防登录SDK调用出错
        //         console.error('调用微信登录SDK出现错误', error);
        //     }
        //     };

         const handleQQLogin = () => {
         };
        // const handleQQLogin = () => {
        //     // 真实应用中需要调用QQ登录SDK
        //     // 以下是一个伪代码示例，你需要根据实际的SDK文档调用QQ登录
        //     try {
        //         qqSDK.login({
        //         success: function (authInfo) {
        //             // 登录成功，获取授权信息并定义后续操作
        //             console.log('QQ登录成功', authInfo);
        //         },
        //         fail: function (error) {
        //             // 登录失败，处理相关的错误信息
        //             console.error('QQ登录失败', error);
        //         }
        //         });
        //     } catch (error) {
        //         // 处理可能的调用错误
        //         console.error('调用QQ登录SDK出现错误', error);
        //     }
        //     };
        onMounted(() => {
            wechatAuth();
        });

        return {
            activeTab,
            loginCredentials,
            registerCredentials,
            phoneLoginCredentials,
            countdown,
            sendButtonText,
            handlePhoneCodeLogin,
            handleRegister,
            handleWechatLogin,
            handleQQLogin,
            sendCode,
            handleUserLogin,
            isPhoneValid,
            showCodeInput,
            checkPhoneValidity,
            handleSendCode,
            isSendingCode,
            authToken
        };
    }
};
</script>

<style scoped>

.centered-container{
    background-color: #f5f5f5;
    height: 100vh
}

.input-error {
    border: 1px solid red;
}

.input-error-message {
    color: red;
    margin-top: 5px;
}

.code-verification-container {
    display: flex;
    align-items: center;
    gap: 10px; /* 根据需要调整间距 */
}



/* ...现有样式... */

/* 星星容器，覆盖整个屏幕 */
.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; /* 确保星星在内容之下 */
}

/* 创建闪烁动画 */
@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* 创建星星 */
.star {
  position: absolute;
  width: 3px; /* 增大星星的宽度 */
  height: 3px; /* 增大星星的高度 */
  background: rgb(151, 40, 40);
  border-radius: 50%; /* 保持为圆形 */
  animation: twinkle 2s infinite;
  opacity: 0.8; /* 可以调整透明度使星星更亮 */
  top: calc(10vh * var(--star-position-vertical, 0.5));
  left: calc(10vw * var(--star-position-horizontal, 0.5));
}

/* 随机化每个星星 */
.star:nth-child(odd) {
  animation-duration: 3s; /* 不同的闪烁速度 */
  width: 3px;
  height: 3px;
}
.star:nth-child(even) {
  animation-duration: 4s;
}

/* 随机化星星的位置 */

/* 可以为星星随机设置大小，使效果更自然 */
.star:nth-child(odd) {
  width: 4px; /* 不同的星星大小 */
  height: 4px;
  animation-duration: 3s; /* 不同的闪烁速度 */
}
.star:nth-child(even) {
  width: 2.5px;
  height: 2.5px;
  animation-duration: 4s;
}
/* .star:nth-child(1) { top: 10%; left: 20%; }
.star:nth-child(2) { top: 15%; left: 25%; } */
/* ...更多星星位置的样式... */
/* 星星闪烁动画 */
@keyframes twinkle {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6; /* 星星在闪烁过程中会变得更暗 */
  }
}

.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 80vh;

}

.tabs-container {
    width: 100%;
    max-width: 600px;
    /* 设置最大宽度, 根据你的需求调整 */
    padding: 20px;
    /* 适当的padding */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* 轻微的阴影效果 */
    border-radius: 8px;
    /* 圆角 */
    background: #ffffff;
    /* 背景色 */
}

.button-custom-width {
  width: auto; /* 举例，根据实际需要调整 */
  margin: 0 auto; /* 水平居中显示 */
}

.button-custom-width_login{
  width: 200px; /* 举例，根据实际需要调整 */
  margin: 0 auto; /* 水平居中显示 */
}

.button-icon {
  width: 16px;      /* 设置图片标识的适当尺寸 */
  height: 16px;     /* 设置图片标识的适当尺寸 */
  margin-right: 8px; /* 在图片和按钮文本之间留出适当的空间 */
}

/* 各种媒体查询可以在这里添加以修改对移动设备的布局 */
@media screen and (max-width: 768px) {
    .tabs-container {
        width: 80%;
        /* 在小屏幕上有更多间距 */
    }
}</style>