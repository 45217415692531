<template>
    <n-layout-header style="display: flex; justify-content: space-between; align-items: center;">
        <!-- 左侧图标和公司名 -->
        <div style="display: flex; align-items: center;">
            <img src="logo.png" alt="Logo" style="width: 30px; height: 30px; margin-right: 10px;">
            <span>Benecd.Ai</span>
        </div>
        
        <!-- 右侧用户信息部分 -->
        <div style="display: flex; align-items: center; margin-left: auto;">
            <n-dropdown trigger="hover" :options="options" @select="handleUserAction">
                <n-avatar style="background-color: #c0c4cc; cursor: pointer;">
                    {{ username }}
                </n-avatar>
            </n-dropdown>
        </div>
    </n-layout-header>
</template>

<script>
import { NLayoutHeader,  NAvatar, NDropdown, } from "naive-ui";
import { ref} from 'vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    NLayoutHeader,
    NAvatar,
    NDropdown,
  },

    setup() {
        const router = useRouter(); 
        const options = ref([
                    { label: '个人信息', key: 'profile' },
                    { label: '退出', key: 'logout' }
                ])
        const username = ref(''); // 假设用户名是已经定义的响应式数据
        username.value = "User"

            
        function handleUserAction(action) {
        switch (action) {
            case 'profile':
            // 导航到个人信息页面
            break;
            case 'logout':
            // 处理退出逻辑
            // 发送登出请求到服务器、清除本地状态、
            // 重定向到登录页面或任何其它逻辑
            localStorage.removeItem('authToken');
            console.log('Logging out the user...');
            router.push('/');
            break;
            default:
            console.log(`未处理的操作：${action}`);
        }
        }

        return {
            options,
            username,
            handleUserAction
        };
    },
};
</script>
