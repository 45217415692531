<!-- UrlSubmitter.vue -->

<template>
  <n-layout style="height: 100vh; overflow: auto ">
    <!-- 头部布局 -->
    <PageHeader />

    <n-layout-content style="padding-top: 64px; height: 90vh; "> <!-- 假设标头高度为 64px -->
      <div class="center-container">
        <div class="form-container">
          <n-space vertical>
            
            <!-- Form -->
            <n-form ref="form" @submit="submitUrl">
              <n-form-item label="URL">
                <n-input
                  v-model:value="url"
                  placeholder="请输入知乎链接"
                />
              </n-form-item>
      
              <n-form-item class="button">
                <n-button type="primary" @click="submitUrl">提交</n-button>
              </n-form-item>
            </n-form>

            <!-- Text area -->
            <n-input
              v-model:value="displayedText"
              type="textarea"
              :rows="13"
              placeholder="这里将显示提取的链接文本"
              readonly
            />

            <!-- Copy button -->
            <n-button ref="copyButton" v-show="displayedText">
              复制文本
            </n-button>

          </n-space>
          <!-- <div v-if="remainingCount !== null" class="remaining-count">
        剩余次数：{{ remainingCount }}
      </div> -->
        </div>
      </div>
    </n-layout-content>
    <!-- 版权信息 -->

    <PageFooter />

  </n-layout>
</template>

  
  <script>
  import { ref,onMounted} from 'vue';
  import ClipboardJS from 'clipboard';
  import PageFooter from "@/components/PageFooter.vue";
  import PageHeader from "@/components/PageHeader.vue";
  import { NButton, NInput, NSpace, NForm, NFormItem, NLayout, NLayoutContent } from 'naive-ui';
  import { useRouter } from 'vue-router';
  export default {
    components: {
      NButton,
      NInput,
      NSpace,
      NForm,
      NFormItem,
      NLayout,
      NLayoutContent,
      PageFooter,
      PageHeader
    },
    setup() {
      const router = useRouter(); 
      function handleUserAction(action) {
        switch (action) {
          case 'profile':
            // 导航到个人信息页面
            break;
          case 'logout':
            // 处理退出逻辑
            // 发送登出请求到服务器、清除本地状态、
            // 重定向到登录页面或任何其它逻辑
            localStorage.removeItem('authToken');
            console.log('Logging out the user...');
            router.push('/');
            break;
          default:
            console.log(`未处理的操作：${action}`);
        }
      }

      const url = ref('');
      const displayedText = ref('');
      const copyButton = ref(null);
      const submitUrl = async (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
          console.log('submit', url.value);


        // 设置倒计时
    let countdown = 15;
    displayedText.value = `请等待，剩余时间 ${countdown} 秒...`;
    const countdownInterval = setInterval(() => {
        countdown--;
        if (countdown > 0) {
        displayedText.value = `请等待，剩余时间 ${countdown} 秒...`;
        }
    }, 1000);

  // 创建请求选项
  const token = localStorage.getItem('authToken');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}` // 注意：具体的头信息键与值格式依据API的要求来设置
    },
    body: JSON.stringify({
      url: url.value // 用户提交的URL地址从 url reactive ref 中取
    })
  };

  try {
      // 执行异步POST请求
      const posturl='https://zhihuurl.wisdomier.com/api/extract-text'
      const response = await fetch(posturl, requestOptions);
       // 清除倒计时
    clearInterval(countdownInterval);

      if (response.status==401) {
        router.push({"name":"AuthenticationPage"})
      }
      // 检查响应状态是否成功
      if (response.ok) {
          // 从响应中解析JSON数据
          const result = await response.json();
          // 假设返回的JSON对象里有一个字段叫做"text"，以下字段应根据你的API实际返回的字段名称进行调整
          displayedText.value = result.data.text;
      } else {
          throw new Error('Network response was not ok');
      }

  } catch (error) {
      console.error('There was an error submitting the URL:', error);
      // 清除倒计时
    clearInterval(countdownInterval);
    displayedText.value = '发生错误！无法获取文本内容。';
      // 这里你可能还想通过UI向用户反馈错误信息
  }
};
    onMounted(() => {
      new ClipboardJS(copyButton.value.$el, {
        text: () => displayedText.value
      });
    });

    // const copyText = async () => {
    //   console.log('Attempting to copy text');
    //   try {
    //     await navigator.clipboard.writeText(displayedText.value);
    //     console.log('Copy text to clipboard:', displayedText.value);
    //     // 此处也可能需要显示用户通知消息
    //   } catch (err) {
    //     console.error('无法复制文本:', err);
    //     // 此处也可能需要显示用户错误通知消息
    //   }
    // };
  
      return {
        handleUserAction,
        url,
        displayedText,
        submitUrl,
        // copyText,
        copyButton
      };
    }
  };
  </script>
  
<style scoped>
  /* 确保视图高度占满整个屏幕，你可能已经有类似的设置 */
html, body {
  height: 100%;
  margin: 0;
}

/* 顶部导航样式 */
.n-layout-header {
  background-color: var(--color-header-bg);
  padding: 12px;
  color: var(--color-text);
  font-weight: bold;
}

.n-layout-header,
.n-layout-footer {
  /* 示例：可以设置最小/最大高度，而非固定高度 */
  min-height: 50px;
  max-height: 70px;
}

/* 居中的包裹容器 */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Form container with some padding and a max-width to ensure it looks good on all screens */
.form-container {
  padding: 20px;
  max-width: 600px; /* Or any max-width you prefer */
  width: 100%;
}


.button{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
/* 可选 - 如果你想让文本区域有固定宽度，也可以像这样设置 */
n-input[type="textarea"] {
  max-width: 500px; /* 确定适合的宽度 */
}
</style>
