import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';

axios.defaults.baseURL = 'https://zhihuurl.wisdomier.com/';

axios.interceptors.request.use(function (config) {
    // 从localStorage获取token
    const token = localStorage.getItem('authToken');
  
    // 如果token存在，则在每次请求中添加它
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  
    return config;
  }, function (error) {
    // 请求错误时做些什么
    return Promise.reject(error);
  });

const app=createApp(App)
app.use(router)
app.mount("#app")
