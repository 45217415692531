<template>
  <n-layout style="height: 100vh; overflow: auto; ">
    <!-- 主体内容部分 -->
    <!-- 顶部用户信息 -->
    <PageHeader />

    <!-- 顶部横向分割线，覆盖导航栏左侧 -->
    <n-layout style="padding-left: 200px;">
      <n-divider />
    </n-layout>

      <div>
        <n-layout has-sider>
          <n-button class="menu_button" v-show="isMobile && isSiderCollapsed" @click="toggleSider">菜单</n-button>
          <n-float-menu class = "float_menu" v-show="isMobile && !isSiderCollapsed" @click="toggleSider">
            <n-menu :options="menuOptions_mobile" v-model:value="currentTool" @update:value="changeTool" style="text-align: left;"/>
          </n-float-menu>
          <!-- 左侧部分 -->
          <n-layout-sider :collapsed="isSiderCollapsed" bordered >
            <n-menu :options="menuOptions" v-model:value="currentTool" @update:value="changeTool" style="text-align: center; "/>
            <n-divider vertical /> <!-- 在菜单下方添加分割线 -->
            <n-button v-show="isMobile && isSiderCollapsed" @click="toggleSider">菜单</n-button>
          </n-layout-sider>

          <!-- 主要内容 -->
          <n-layout-content style="padding: 24px; text-align: center; ">
            <n-grid :x-gap="12" :y-gap="8">
              <n-grid-item v-for="tool in toolList" :key="tool.name" :span="isMobile ? 24 : 6" class="tool-card">
                <n-card @click="goToTool(tool.link)">
                  <img :src="tool.image" alt="" />
                  <div>{{ tool.name }}</div>
                </n-card>
              </n-grid-item>
            </n-grid>
          </n-layout-content>
        </n-layout>
      </div>

    <PageFooter />
  </n-layout>
</template>





<script>
import { defineComponent, ref,onMounted, computed  } from 'vue';
import { NLayout, NLayoutSider, NLayoutContent, NGrid, NGridItem, NMenu, NCard } from 'naive-ui';
import { useRouter } from 'vue-router';
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
export default defineComponent({
  components: {
    NLayout,
    NLayoutSider,
    NLayoutContent,
    NGrid,
    NGridItem,
    NMenu,
    NCard,
    PageFooter,
    PageHeader
  },
  setup() {
    // const isMobile = ref(window.innerWidth < 768);
    const windowWidth = ref(window.innerWidth);
    const breakpoint = 768; // 设置断点，小于这个值时认为是移动设备

    // 根据屏幕宽度判断是否是移动设备
    const isMobile = computed(() => {
      return windowWidth.value < breakpoint;
    });


    const isSiderCollapsed = ref(isMobile.value); // 侧边栏默认为折叠状态

    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth;
      isSiderCollapsed.value = windowWidth.value < breakpoint;
    });
     // 手动切换侧边栏的展开和折叠
    function toggleSider(){
      isSiderCollapsed.value = !isSiderCollapsed.value;
    }
    const router = useRouter(); 
    /* 你可能需要基于实际情况调整这里的数据格式 */
    const toolList = ref([
      { name: '知乎文字提取', image: 'tool1.png', link: '/submiturl' },
      { name: 'Gif动画生成', image: 'tool2.png', link: '/gifgenerate' },
      { name: '趣味手绘动画', image: 'tool3.png', link: '/gifdrawhappy'},
      // { name: '视频剪辑', image: 'tool2.png', link: '/tool3' },
      // { name: '文生文', image: 'tool4.png', link: '/tool4' },
      // { name: '文生图', image: 'tool5.png', link: '/tool5' },
      // 其他工具 ...
    ]);
    const menuOptions = ref([
      { label: '办公工具类', key: 'category1' },
      // { label: '生活工具类', key: 'category2' },
      // { label: '娱乐工具类', key: 'category3' },
      // 其他分类 ...
    ]);
    const menuOptions_mobile = ref([
      { label: '办公', key: 'category1' },
      // { label: '生活', key: 'category2' },
      // { label: '娱乐', key: 'category3' },
      // 其他分类 ...
    ]);
   
    const currentTool = ref('category1'); // 当前激活的导航菜单项
   

    // 改变工具的函数
    const changeTool = (toolKey) => {
      // 更新工具列表的显示或执行其他相关操作
      currentTool.value = toolKey;
      // 进一步的，你可能需要根据当前工具加载具体的数据或切换组件
    }
    
    function goToTool(link) {
      // 导航到对应的工具路由
      // 使用vue-router的programmatic navigation特性
      router.push(link);
    }

    onMounted(() => {
      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
      });
    });
    return {
      toolList,
      menuOptions,
      menuOptions_mobile,
      currentTool,
      goToTool,
      changeTool,
      isMobile,
      isSiderCollapsed,
      toggleSider,
    };
  }
});
</script>

<!-- ...Styles here... -->

<style>


/* 定义颜色变量 */
:root {
  --color-primary: #4CAF50; /* 主要的绿色调，比如按钮的颜色 */
  --color-primary-light: #81C784; /* 较浅的绿色调，用于悬浮或点击状态 */
  --color-primary-dark: #388E3C; /* 较深的绿色调，用于阴影或边框 */
  --color-text: #ffffff; /* 文字颜色 */
  --color-layout-bg: #f0f0f0; /* 布局背景颜色 */
  --color-header-bg: #4CAF50; /* 头部背景颜色 */
  --color-sider-bg: #388E3C; /* 侧边栏背景颜色 */
  --color-card-bg: #ffffff; /* 卡片背景颜色 */
}


/* 顶部导航样式 */
.n-layout-header {
  background-color: var(--color-header-bg);
  padding: 12px;
  color: var(--color-text);
  font-weight: bold;
}


/* 如果 n-layout-content 中有超出容器的内容，需要确保内容适应或适当地滚动 */
.n-layout-footer
.n-layout-content {
  min-height: 100vh; /* 根据 header/footer 实际高度调整 */
  overflow-y: hidden; /* 允许内容区域内滚动，如果必要 */
}

/* 对 header 和 footer 的样式进行调整，确保他们不会因为固定高度冲突造成额外滚动 */
.n-layout-header,
.n-layout-footer {
  /* 示例：可以设置最小/最大高度，而非固定高度 */
  min-height: 50px;
  max-height: 70px;
}

.n-layout{
  background-color: rgb(255, 255, 255);
}

/* 侧边栏样式 */
.n-layout-sider {
  background-color: var(--color-sider-bg);
}

.n-layout-sider{
  height: 100vh ;
  width: 200px; 
  background-color: white; 
  overflow: auto;
}

.n-menu {
  --n-item-color-active: var(--color-primary-light);
  --n-item-color-hover: var(--color-primary-light);
}

/* 卡片样式 */
.n-card {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid var(--color-primary-light);
}

.n-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.n-card img {
  max-width: 100%;
  border-bottom: 1px solid var(--color-primary-light);
}

/* 用户信息和下拉菜单样式 */
.n-avatar {
  background-color: var(--color-primary);
  color: var(--color-text);
  cursor: pointer;
}

.n-dropdown :deep(.n-menu) {
  --n-item-color-active: var(--color-primary-light);
  --n-item-color-hover: var(--color-primary-light);
}

:root {
  --color-text: #333333; /* 文字颜色改为偏深色，增强可读性 */
  --color-layout-bg: #f0f0f0; /* 布局背景颜色 */
  --color-header-bg: var(--color-primary); /* 使用定义好的--color-primary */
  --color-sider-bg: var(--color-primary); /* 使用定义好的--color-primary */
  --color-card-bg: #ffffff; /* 卡片背景颜色 */
}



/* 确保hover效果在用户信息和卡片上都有 */
.n-avatar:hover, .n-card:hover {
  opacity: 0.85; /* 降低透明度提供反馈 */
}

/* 修改菜单和卡片hover效果 */
.n-menu-link:hover, .n-card:hover {
  background-color: rgba(90, 169, 230, 0.1); /* 使用调整后的主题颜色 */
}

.tool-card {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.24);
}


/* 添加媒体查询以适应不同屏幕尺寸 */
@media (max-width: 768px) {
  /* 在移动端可用的样式 */

  .n-layout-header,
  .n-layout-footer {
    text-align: center;
  }

  /* 调整布局为单列显示 */
  .tool-card .n-card {
    width: 100%;
    margin-bottom: 8px;
  }

  .n-layout-sider {
    width: 0px !important; /* 确保侧边栏宽度在桌面端为 200px */
  }
  .float_menu{
    height: 100vh ;
    width: 100px; 
    background-color: white; 
    overflow: auto;
    font-size: 20px;
  }

  .menu_button{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: green;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 20px;
    margin-left: -5px;
    right: -100px;
    top: 100px;
    transform: translateY(-50%);
    
  }
}

@media (min-width: 769px) {
  /* 在桌面端可用的样式 */

  .n-layout-sider {
    width: 200px !important; /* 确保侧边栏宽度在桌面端为 200px */
  }
}

.tool-card .n-card {
  cursor: pointer;
}

/* 定制图片样式 */
.tool-image {
  max-width: 100%;
  height: auto;
}

/* 使用媒体查询适应不同设备屏幕 */
@media (max-width: 768px) {
  .tool-card {
    text-align: center; /* 手机端左对齐文字 */
  }
}


</style>

