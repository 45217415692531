<template>
  <div>
    <h1>微信登录</h1>
    <!-- 二维码显示区域 -->
    <div v-if="qrCodeUrl">
      <qrcode-vue :value="qrCodeUrl" :size="400" />
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import axios from 'axios';

export default {
  name: 'WeChatLogin',
  components: {
    QrcodeVue
  },
  data() {
    return {
      qrCodeUrl: '',
      qrCode: '',
      polling: null
    };
  },
  created() {
    this.getQrCode();
  },
  methods: {
    getQrCode() {
      axios.get('https://zhihuurl.wisdomier.com/wechat_login_qrcode')
        .then(response => {
          this.qrCodeUrl = response.data.url;
          this.qrCode = response.data.code;
          this.startPolling();
        })
        .catch(error => {
          console.error('Error fetching QR code:', error);
        });
    },
    startPolling() {
    const maxTime = 300000; // 5分钟，以毫秒为单位
    this.polling = setInterval(() => {
      if (this.elapsedTime >= maxTime) {
        clearInterval(this.polling);
        console.log('Polling stopped after 5 minutes');
        alert('Session expired. Please login again.');
        this.$router.push('/');
        return;
      }

      axios.get(`https://zhihuurl.wisdomier.com/wx_login/?code=${this.qrCode}`)
        .then(response => {
          if (response.data.token) {
            clearInterval(this.polling);
            this.handleLoginSuccess(response.data.token);
          }
        })
        .catch(error => {
          console.error('Error polling login status:', error);
        });

      this.elapsedTime += 3000; // 每次轮询增加3秒
    }, 3000); // 每3秒检查一次
  },
    handleLoginSuccess(token) {
      // 处理登录成功逻辑，例如保存token
      localStorage.setItem('authToken', token);

// 重定向到/toolselector
      this.$router.push('/toolselector');
      console.log('Login successful, token:', token);
      // 你可以在这里重定向或更新应用状态
    }
  }
};
</script>
