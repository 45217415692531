<template>
    <!-- 最外层布局容器 -->

  <n-layout style="height: 100vh; overflow: auto;">
    <!-- 头部布局 -->
    <PageHeader />

    <n-layout-content style="padding-top: 64px; height: 90vh; "> <!-- 假设标头高度为 64px -->
      <div class="image-uploader">
      <div class="image-container">
        <div class="image-item" v-for="(box, index) in boxes" :key="index">
          <div class="box-container">
            <div class="box" @click="addImage(index)" :data-index="index">
              <img v-if="box.image" :src="box.image" alt="Image" />
              <div v-else class="placeholder">点击添加图片</div>
            </div>
          </div>
          <input type="text" v-model="box.text" placeholder="添加图片的文本" />
        </div>
        <div class="add-button-container">
          <button class="add-button" @click="addBox">+</button>
        </div>
      </div>
    
        <div class="result-container">
          <div class="result-item" v-if="gifResult">
            <img :src="gifResult" alt="GIF Result" />
          </div>
        </div>
    
        <input type="file" @change="importImage" accept="image/*" ref="fileInputRef" style="display: none" />
        
        
        
        <n-form class="all_display_button">
          <n-form-item style="display: flex; justify-content: flex-end;">
            <span style="margin-right: 10px;">帧间间隔(ms)：</span>
            <n-input-number v-model:value="frameInterval" :min="1" :max="5000" style="flex-shrink: 0; width: 200px;" />
          </n-form-item>
          <n-form-item style="display: flex; justify-content: flex-end;">
            <span style="margin-right: 10px;">保真度(1~31)：</span>
            <n-input-number v-model:value="quality" :min="1" :max="31" style="flex-shrink: 0; width: 200px;" />
          </n-form-item>
          <n-form-item style="display: flex; justify-content: flex-end;">
            <span style="margin-right: 10px;">文本大小(1~100)：</span>
            <n-input-number v-model:value="fontSize" :min="1" :max="100" style="flex-shrink: 0; width: 200px;" />
          </n-form-item>

          <n-form-item class="button">
            <n-button class="generate-button" type="primary" @click="generateGif">生成GIF</n-button>
            <n-button class="download-button" type="primary" @click="downloadGif">下载GIF</n-button>
            <n-button class="refresh-button" type="primary" @click="refresh">刷新</n-button>
          </n-form-item>
        </n-form>
      </div>
    </n-layout-content>
    <!-- 版权信息 -->
    <PageFooter />
  </n-layout>
  </template>

  <script>
  import { useRouter } from 'vue-router';
  import { reactive, ref} from 'vue';
  import gifshot from 'gifshot';
  import PageFooter from "@/components/PageFooter.vue";
  import PageHeader from "@/components/PageHeader.vue";
  import { NButton, NForm, NFormItem, NInputNumber, NLayout, NLayoutContent} from 'naive-ui';
  import axios from "axios"
  export default {
    name: 'ImageUploader',
    components: {
      NButton,
      NForm,
      NFormItem,
      NInputNumber, 
      NLayout,
      NLayoutContent,
      PageFooter,
      PageHeader
    },
    setup() {

    axios.defaults.baseURL  =  'http://127.0.0.1:9000/';
    axios.defaults.headers.post['Content-Type']  =  'application/json';
    axios.defaults.headers.common['X-Requested-With']  =  'XMLHttpRequest';
    axios.defaults.headers.common['Referrer-Policy']  =  'origin';
    const router = useRouter(); 


    const boxes = reactive([{ image: null, text: '' }]);
    const gifResult = ref('');
    const fileInputRef = ref(null);

    const frameInterval = ref(1000); // 帧间间隔，默认为1
    const quality = ref(20); // 保真度，默认为20
    const fontSize = ref(50); // 字体大小，默认为100
  
    const addImage = (index) => {
        fileInputRef.value.click();
        fileInputRef.value.dataset.index = index; // 设置当前索引
      };
    const importImage = (event) => {
      const file = event.target.files[0];
      if (!file) {
        // 如果没有选择文件，直接返回
        return;
      }
      const reader = new FileReader();
      const currentIndex = event.target.dataset.index; // 获取当前索引
      reader.onload = (e) => {
        const src = e.target.result;
        const image = new Image();
        image.src = src;
        image.onload = () => {
          const width = image.width;
          const height = image.height;
          if (currentIndex === '0') {
            // 如果是第一张图片，保存长宽信息
            boxes[currentIndex] = { image: src, text: boxes[currentIndex].text, width, height };
          } else {
            // 如果后面添加的图片与第一张图的长宽不一样，则进行resize
            const firstImage = new Image();
            firstImage.src = boxes[0].image;
            firstImage.onload = () => {
              const firstWidth = firstImage.width;
              const firstHeight = firstImage.height;
              if (width !== firstWidth || height !== firstHeight) {
                const canvas = document.createElement('canvas');
                canvas.width = firstWidth;
                canvas.height = firstHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, firstWidth, firstHeight);
                const resizedSrc = canvas.toDataURL();
                boxes[currentIndex] = { image: resizedSrc, text: boxes[currentIndex].text, width: firstWidth, height: firstHeight };
              } else {
                boxes[currentIndex] = { image: src, text: boxes[currentIndex].text, width, height };
              }
            };
          }
          if (currentIndex === boxes.length - 1) {
            addBox();
          }
        };
      };
      reader.readAsDataURL(file);
    };
  
    const addBox = () => {
       boxes.push({ image: null, text: '' });
      };
    
    // 新的函数用于创建带有文本的图像
    async function createImageWithText(box, fontSize) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        canvas.width = box.width;
        canvas.height = box.height;
        
        const context = canvas.getContext('2d');
        const image = new Image();
        
        image.onload = () => {
          context.drawImage(image, 0, 0);
          
          context.font = `${fontSize}px Arial`;
          context.fillStyle = 'red';
          context.fillText(box.text, 10, fontSize); // 第二个参数为开始绘制文本的Y坐标
          
          resolve(canvas.toDataURL());
        };
        
        image.onerror = reject;
        image.src = box.image; // 设置图像源
      });
    }

    const generateGifWithGifshot = async (boxes, frameInterval, quality, fontSize) => {
      // 先创建所有带文本的图像
      const imagesWithText = await Promise.all(
        boxes.map(box => createImageWithText(box, fontSize))
      );

      // 用gifshot 创建GIF
      const options = {
        'gifWidth': boxes[0].width,
        'gifHeight': boxes[0].height,
        'images': imagesWithText,
        'interval': frameInterval / 1000,
        'quality': 32 - quality, // Adjust as needed (逆序，gifshot中的quality参数与gif.js不同)
        'sampleInterval': 10 // 控制采样率，数字越低(最低为1)质量越高但生成速度越慢
      };

      return new Promise((resolve, reject) => {
        gifshot.createGIF(options, function(obj) {
          if (!obj.error) {
            const gifImageURL = obj.image;
            resolve(gifImageURL);
          } else {
            console.error('生成GIF时出错:', obj.error);
            reject(obj.error);
          }
        });
      });
    };

    const generateGif = async () => {
      try {
        const gifImageURL = await generateGifWithGifshot(boxes, frameInterval.value, quality.value, fontSize.value);
        gifResult.value = gifImageURL;
        console.log('GIF已创建:', gifImageURL);
      } catch (error) {
          console.log('生成GIF过程中出错:', error);
      }
    };
   
    const downloadGif = () => {
      const link = document.createElement('a');
      link.href = gifResult.value;
      link.download = 'result.gif'; // 指定下载的文件名

      // 不需要在页面上可见，也不需要打开新标签页
      link.style.display = 'none';

      document.body.appendChild(link);
      
      // 这里对download属性的检查是为了确保当我们尝试启动下载时，链接实际支持download属性。
      if (link.download !== undefined) {
        link.click(); // 使用真实的点击行为来触发下载
      }
      
      document.body.removeChild(link); // 点击之后从DOM中移除
    };


    const refresh = () => {
        boxes.splice(0, boxes.length);
        boxes.push({ image: null, text: '' });
        gifResult.value = '';
        };
  
      return {
        boxes,
        gifResult,
        fileInputRef,
        addImage,
        importImage,
        addBox,
        generateGif,
        downloadGif,
        refresh,
        frameInterval,
        quality,
        fontSize,
        router,
      };
    },
  };
  </script>
  
  
  <style>
  
/* 顶部导航样式 */
.n-layout-header {
  background-color: var(--color-header-bg);
  padding: 12px;
  color: var(--color-text);
  font-weight: bold;
}

.n-layout-header,
.n-layout-footer {
  /* 示例：可以设置最小/最大高度，而非固定高度 */
  min-height: 50px;
  max-height: 70px;
}

.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  
  .image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .image-item {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .box-container {
    display: flex;
    align-items: flex-start;
  }
  
  .box {
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
  }
  
  .box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .placeholder {
    color: #999;
  }
  

  


  .result-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .result-item {
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 200px;
    height: 200px;
  }
  
  .result-item img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  input[type='file'] {
    display: none;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .generate-button {
    margin-left: 40px;;
    margin-right: 10px;
    background-color: green;
    color: #fff;
    font-size: 15px;
  }
  
  .download-button {
    /* margin-top: 10px; */
    background-color: green;
    color: #fff;
    font-size: 15px;
  }

  .refresh-button {
    margin-left: 10px;
    background-color: green;
    color: #fff;
    font-size: 15px;
  }

@media(min-width: 769px){
  .add-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    position: relative;
  }
  
  .add-button {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: green;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: -100px;
    top: 100px;
    transform: translateY(-50%);
  }
}
@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
  }
  .image-item {
    margin-bottom: 20px;
  }

  .add-button-container {
    display: flex;
    justify-content: center; /* 默认居中 */
    align-items: center; /* 垂直居中 */
    margin-top: 10px;
}

.add-button {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: green;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

}
  </style>
  